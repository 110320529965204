import { createTheme } from '@mui/material/styles';
import { CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon, CheckBoxOutlined as CheckBoxOutlinedIcon } from '@mui/icons-material';
import figmaTheme from './figmaTheme';

export const rawTypography = {
  'header-xl': {
    fontSize: figmaTheme.fontSizes['header-xl'],
    fontWeight: figmaTheme.fontWeights['header-xl'],
    lineHeight: figmaTheme.lineHeights['header-xl'],
    letterSpacing: figmaTheme.letterSpacings['header-xl'],
  },
  'header-l': {
    fontSize: figmaTheme.fontSizes['header-l'],
    fontWeight: figmaTheme.fontWeights['header-l'],
    lineHeight: figmaTheme.lineHeights['header-l'],
    letterSpacing: figmaTheme.letterSpacings['header-l'],
  },
  'header-m': {
    fontSize: figmaTheme.fontSizes['header-m'],
    fontWeight: figmaTheme.fontWeights['header-m'],
    lineHeight: figmaTheme.lineHeights['header-m'],
    letterSpacing: figmaTheme.letterSpacings['header-m'],
  },
  'body-s-regular': {
    fontSize: figmaTheme.fontSizes['body-s-regular'],
    fontWeight: figmaTheme.fontWeights['body-s-regular'],
    lineHeight: figmaTheme.lineHeights['body-s-regular'],
    letterSpacing: figmaTheme.letterSpacings['body-s-regular'],

  },
  'body-xs-bold': {
    fontSize: figmaTheme.fontSizes['body-xs-bold'],
    fontWeight: figmaTheme.fontWeights['body-xs-bold'],
    lineHeight: figmaTheme.lineHeights['body-xs-bold'],
    letterSpacing: figmaTheme.letterSpacings['body-xs-bold'],
  },
  'body-xs-regular': {
    fontSize: figmaTheme.fontSizes['body-xs-regular'],
    fontWeight: figmaTheme.fontWeights['body-xs-regular'],
    lineHeight: figmaTheme.lineHeights['body-xs-regular'],
    letterSpacing: figmaTheme.letterSpacings['body-xs-regular'],
  },
  'body-xxs-regular': {
    fontSize: figmaTheme.fontSizes['body-xxs-regular'],
    fontWeight: figmaTheme.fontWeights['body-xxs-regular'],
    lineHeight: figmaTheme.lineHeights['body-xxs-regular'],
    letterSpacing: figmaTheme.letterSpacings['body-xxs-regular'],
  },
  'body-xxs-medium': {
    fontSize: figmaTheme.fontSizes['body-xxs-medium'],
    fontWeight: figmaTheme.fontWeights['body-xxs-medium'],
    lineHeight: figmaTheme.lineHeights['body-xxs-medium'],
    letterSpacing: figmaTheme.letterSpacings['body-xxs-medium'],
  },
};

export const rawTheme = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        'html, body, div#root, div#root>form': {
          width: '100%',
          height: '100%',
        },
        'div#root>form': {
          display: 'flex',
          flexDirection: 'column',
        },
        [`input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus,
        textarea:-webkit-autofill,
        textarea:-webkit-autofill:hover,
        textarea:-webkit-autofill:focus,
        select:-webkit-autofill,
        select:-webkit-autofill:hover,
        select:-webkit-autofill:focus`]: {
          fontSize: figmaTheme.fontSizes['body-xs-regular'],
          fontWeight: figmaTheme.fontWeights['body-xs-regular'],
          lineHeight: figmaTheme.lineHeights['body-xs-regular'],
          letterSpacing: figmaTheme.letterSpacings['body-xs-regular'],
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
        disableFocusRipple: true,
        // eslint-disable-next-line react/jsx-filename-extension
        icon: <CheckBoxOutlineBlankOutlinedIcon />,
        checkedIcon: <CheckBoxOutlinedIcon />,
      },
      styleOverrides: {
        root: {
          '& input + svg ': {
            height: '18px',
            width: '18px',
            fill: figmaTheme.colors['neutral-80'],

          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 4,
          fontSize: figmaTheme.fontSizes['body-xxs-regular'],
          fontWeight: figmaTheme.fontWeights['body-xxs-regular'],
          lineHeight: figmaTheme.lineHeights['body-xxs-regular'],
          letterSpacing: figmaTheme.letterSpacings['body-xxs-regular'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: figmaTheme.fontSizes['body-xs-regular'],
          fontWeight: figmaTheme.fontWeights['body-xxs-bold'],
          height: '56px',
          padding: '16px',
        },
      },
      defaultProps: {
        disableFocusRipple: true,
        disableRipple: true,
        disableTouchRipple: true,
      },
      variants: [
        {
          props: {
            variant: 'text',
          },
          style: {
            backgroundColor: 'transparent',
            color: figmaTheme.colors['secondary-main'],
            fontSize: figmaTheme.fontSizes['elements-button'],
            fontWeight: figmaTheme.fontWeights['elements-button'],
            lineHeight: figmaTheme.lineHeights['elements-button'],
            letterSpacing: figmaTheme.letterSpacings['elements-button'],
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          'header-xl': 'h1',
          'header-l': 'h2',
          'header-m': 'h3',
          'body-xs-regular': 'p',
          'body-xxs-regular': 'p',
          'body-xs-bold': 'p',
          'body-xxs-medium': 'p',
        },
      },
    },
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontSize: 14,
    ...rawTypography,
  },
  palette: {
    primary: {
      main: figmaTheme.colors['primary-main'],
      light: figmaTheme.colors['primary-light'],
      dark: figmaTheme.colors['primary-dark'],
    },
    secondary: {
      light: figmaTheme.colors['secondary-light'],
      main: figmaTheme.colors['secondary-main'],
      dark: figmaTheme.colors['secondary-dark'],
    },
    error: {
      light: figmaTheme.colors['error-light'],
      main: figmaTheme.colors['error-main'],
      dark: figmaTheme.colors['error-dark'],
    },
    neutral: {
      '00': figmaTheme.colors['neutral-00'],
      '05': figmaTheme.colors['neutral-05'],
      10: figmaTheme.colors['neutral-10'],
      20: figmaTheme.colors['neutral-20'],
      30: figmaTheme.colors['neutral-30'],
      40: figmaTheme.colors['neutral-40'],
      50: figmaTheme.colors['neutral-50'],
      60: figmaTheme.colors['neutral-60'],
      70: figmaTheme.colors['neutral-70'],
      80: figmaTheme.colors['neutral-80'],
      90: figmaTheme.colors['neutral-90'],
      100: figmaTheme.colors['neutral-100'],
    },
  },
};

export default createTheme(rawTheme);
