import { FLOW_KEY, ROUTE_KEY } from './constants';

/**
 * Declaration file of the different flows, these include the different routes to be used.
 */

const FLOWS = {
  [FLOW_KEY.main]: [
    ROUTE_KEY['/'],
    ROUTE_KEY['/addresses'],
    ROUTE_KEY['/contact'],
    ROUTE_KEY['/service-summary'],
  ],
};

export default FLOWS;
