import { FLOW_KEY } from './constants';

/**
 * Default values to intitialize forms
 */

const packageDefaultValues = {
  weight: '',
  length: '',
  width: '',
  height: '',
};

const shippingDate = '';

const address = {
  address: '',
  country: '',
  city: '',
  zip: '',
  details: '',
};

const contact = {
  name: '',
  surname: '',
  email: '',
  phone: '',
};

const packages = [packageDefaultValues];

const keepUpdates = false;

const agreeConditions = false;

export const FLOW_DEFAULT_VALUES = {
  [FLOW_KEY.main]: {
    contact,
    destination: address,
    keepUpdates,
    origin: address,
    packages,
    shippingDate,
    agreeConditions,
  },
};

const DEFAULT_VALUES = {
  address,
  contact,
  keepUpdates,
  package: packageDefaultValues,
  packages,
  shippingDate,
  agreeConditions,
};

export default DEFAULT_VALUES;
