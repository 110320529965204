import { Form, Formik } from 'formik';
import { equals, mergeDeepLeft } from 'ramda';
import {
  Suspense, useCallback, useEffect, useRef,
} from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  Navigate, Route, Routes, useLocation, useNavigate,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import state from '../../app/state';

import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import { FormLayout } from '../components/FormLayout';
import { StepService } from '../components/StepService';
import { FLOW_KEY, localFormStorage } from './constants';
import { FLOW_DEFAULT_VALUES } from './defaultValues';
import FLOWS from './flows';
import ROUTES_OPTIONS from './options';
import ROUTES from './routes';
import { FLOW_VALIDATIONS } from './validations';

// TODO: [STATIC VARIABLE], to allow different flows in future
const DEFAULT_FLOW = FLOW_KEY.main;

let checkInitialValidation = false;
let isValidationApplied = false;

const Pages = () => {
  const { t } = useTranslation();
  const formikRef = useRef();
  const parameters = useRecoilValue(state.parameters);
  const location = useLocation();
  const navigate = useNavigate();
  const properFlow = FLOWS[DEFAULT_FLOW];
  const properValidation = FLOW_VALIDATIONS[DEFAULT_FLOW];
  const properInitialValues = { ...FLOW_DEFAULT_VALUES[DEFAULT_FLOW], language: parameters.lang };

  useEffect(() => {
    if (formikRef.current) formikRef.current.validateForm();
  }, []);

  if (!properFlow) throw new Error(`Flow [${DEFAULT_FLOW}] not found`);

  if (!properValidation) throw new Error(`Validation flow [${DEFAULT_FLOW}] not found`);

  if (!properInitialValues) throw new Error(`Default values flow [${DEFAULT_FLOW}] not found`);

  return (
    // Global form
    <Formik
      innerRef={formikRef}
      validationSchema={properValidation}
      initialValues={properInitialValues}
      onReset={() => {
        localFormStorage.clear();
      }}
      initialStatus={{
        step: location.pathname,
        flow: properFlow,
        flowName: DEFAULT_FLOW,
      }}
    >
      {({
        status, setStatus, values, setValues, resetForm, isValidating, errors, isValid,
      }) => {
        if (isValidating && !checkInitialValidation) {
          checkInitialValidation = true;
        }
        if (status.step !== location.pathname) setStatus({ ...status, step: location.pathname });

        // Calculate steps in current flow
        const currStepIdx = status.flow.findIndex(equals(status.step));
        const prevStep = status.flow[currStepIdx - 1] || undefined;
        const nextStep = status.flow[currStepIdx + 1] || status.step;
        const options = ROUTES_OPTIONS[status.step] || {};

        // Subform handlers
        const snapshotState = useCallback((newValues) => {
          setValues(mergeDeepLeft(newValues), true);
        }, [setValues]);

        const goToNextStep = (newValues) => {
          isValidationApplied = true;
          snapshotState(newValues);
          setStatus({ ...status, step: nextStep });
          navigate(nextStep);
        };

        if (!checkInitialValidation) return null;

        const needRedirect = !isValidationApplied && options && (
          (options.requiredValues === '*' && !isValid)
        || (Array.isArray(options.requiredValues)
         && options.requiredValues?.some((requiredValue) => Boolean(errors[requiredValue])))
        );

        if (needRedirect) {
          return <Navigate to="/" />;
        }
        return (
          <>
            {options?.title && (
            <Helmet>
              <title>
                BoB | Booking
                {' - '}
                {t(options.title)}
              </title>
            </Helmet>
            )}
            <Form>
              <Header
                backRoute={options.backDisabled ? undefined : prevStep}
                logoCallback={resetForm}
              />
              <FormLayout>
                {!options.stepCountDisabled && <StepService />}
                <Suspense fallback={null}>
                  <Routes>
                    { status.flow.map((route) => {
                      const Component = ROUTES[route];

                      if (!Component) throw new Error('Invalid route component');

                      return (
                      // Routes depending of selected flow
                        <Route
                          key={route}
                          path={route}
                          element={(
                            <Component serviceForm={{
                              values,
                              handlers: {
                                goToNextStep,
                                snapshotState,
                              },
                            }}
                            />
)}
                        />
                      );
                    })}

                    <Route path="*" element={<Navigate to="/" />} />
                  </Routes>
                </Suspense>
              </FormLayout>
              {options.showFooter && <Footer />}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default Pages;
