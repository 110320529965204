import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { makeStyles } from '@mui/styles';

const useFullButtonStyles = makeStyles((theme) => ({
  rootMobile: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    padding: theme.spacing(1.5, 2),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.neutral['00'],
    borderTopStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,

    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  buttonMobile: {
    display: 'flex',
    pointerEvents: 'all',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      pointerEvents: 'none',
    },
  },
  buttonDesktop: {
    display: 'none !important',
    pointerEvents: 'none',
    height: theme.spacing(6),

    [theme.breakpoints.up('md')]: {
      display: 'flex !important',
      pointerEvents: 'all',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

export const FullButton = ({ children, _ref, ...props }) => {
  const classes = useFullButtonStyles();
  const loadingIndicator = <CircularProgress color="primary" size={22} />;
  return (
    <>
      <LoadingButton
        fullWidth
        variant="contained"
        loadingIndicator={loadingIndicator}
        {...props}
        className={classes.buttonDesktop}
        ref={_ref}
      >
        {children}
      </LoadingButton>
      <div className={classes.rootMobile}>
        <LoadingButton
          fullWidth
          className={classes.buttonMobile}
          variant="contained"
          loadingIndicator={loadingIndicator}
          ref={_ref}
          {...props}
        >
          {children}
        </LoadingButton>
      </div>
    </>

  );
};

FullButton.propTypes = {
  children: PropTypes.node.isRequired,
  _ref: PropTypes.objectOf(PropTypes.any),
};

FullButton.defaultProps = {
  _ref: undefined,
};
