import { lazy } from 'react';
import { ROUTE_KEY } from './constants';
import Packages from '../Packages';

const DELAY_PREFETCH = 3000;

const lazyWithPreload = (factory) => {
  const Component = lazy(factory);
  Component.preload = factory;
  return Component;
};

const routes = {
  Addresses: lazyWithPreload(() => import(/* webpackChunkName: 'Addresses' */'../Addresses')),
  Contact: lazyWithPreload(() => import(/* webpackChunkName: 'Contact' */'../Contact')),
  ServiceSummary: lazyWithPreload(() => import(/* webpackChunkName: 'ServiceSummary' */'../ServiceSummary')),
};

const preloadRoutes = () => Object.values(routes)
  .forEach(({ preload }) => preload());

document.addEventListener('readystatechange', ({ target }) => {
  if (target.readyState === 'complete') {
    setTimeout(preloadRoutes, DELAY_PREFETCH);
  }
});

// Declaration file for all routes

const ROUTES = {
  [ROUTE_KEY['/']]: Packages,
  [ROUTE_KEY['/addresses']]: routes.Addresses,
  [ROUTE_KEY['/contact']]: routes.Contact,
  [ROUTE_KEY['/service-summary']]: routes.ServiceSummary,
};

export default ROUTES;
