import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';

const useFormLayoutStyles = makeStyles((theme) => ({
  root: {
    margin: '0 auto',
    width: '100%',
    maxWidth: theme.spacing(57),
    padding: theme.spacing(4, 2, 10),
    flexGrow: 1,

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0),
    },
  },
}));

export const FormLayout = ({ children }) => {
  const classes = useFormLayoutStyles();

  return (
    <Paper className={classes.root} elevation={0}>
      {children}
    </Paper>
  );
};

FormLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
