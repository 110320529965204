import { ROUTE_KEY } from './constants';

/**
 * Declaration file for all routing options
 *
 * - backDisabled - {boolean} - Disable back button in header
 * - stepCountDisabled - {boolean} - Omit page to the global count step
 * - showFooter - {boolean} - Enable footer in page
 * - requiredValues {Array<string> | '*' } - Check validation in global form, when state is invalid
 *                                            it redirect to the first step of form flow
 * - title {string} - Title to include in html head
 */
const ROUTES_OPTIONS = {
  [ROUTE_KEY['/service-summary']]: {
    title: 'pages:summary.htmlTitle',
    backDisabled: true,
    stepCountDisabled: true,
    showFooter: true,
    requiredValues: '*',
  },
  [ROUTE_KEY['/contact']]: {
    title: 'pages:contact.htmlTitle',
    requiredValues: [
      'destination',
      'origin',
      'packages',
      'shippingDate',
    ],
  },
  [ROUTE_KEY['/addresses']]: {
    title: 'pages:addresses.htmlTitle',
    requiredValues: [
      'packages',
    ],
  },
  [ROUTE_KEY['/']]: {
    title: 'pages:packages.htmlTitle',
  },
};

export default ROUTES_OPTIONS;
