import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiCard from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const CARD_STYLE = ({ palette }, gap) => ({
  overflow: 'inherit',
  position: 'relative',
  color: palette.neutral['100'],
  fontWeight: 700,
  lineHeight: '24px',
  fontSize: '16px',
  borderRadius: '8px',
  '& .MuiCard-root': {
    borderRadius: '8px',
  },
  '& .MuiCardContent-root': {
    display: 'flex',
    flexDirection: 'column',
    gap,
    p: 2,
  },
});

const TITLE_STYLE = {
  fontWeight: 700,
  lineHeight: '24px',
  fontSize: '16px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1,
};

function Card({
  children, title, button, gap, titleIcon,
}) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  return (
    <MuiCard variant="outlined" sx={CARD_STYLE(theme, gap)}>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="header-m"
            sx={TITLE_STYLE}
          >
            {titleIcon}
            {title && typeof title === 'string' && i18n.exists(title)
              ? t(title)
              : title}
          </Typography>
          {button}
        </Box>
        {children}
      </CardContent>
    </MuiCard>
  );
}

Card.propTypes = {
  gap: PropTypes.number,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node.isRequired,
  button: PropTypes.node,
  titleIcon: PropTypes.node,
};

Card.defaultProps = {
  gap: 2,
  button: null,
  titleIcon: null,
};

export default Card;
