import { AddCircleOutline as AddCircleOutlineIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Button, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useFormik } from 'formik';
import {
  append, assocPath, dissoc, lensProp, over, path, pipe, values as rValues,
} from 'ramda';
import { useTranslation } from 'react-i18next';

import Card from '../components/Card';
import { FullButton } from '../components/FullButton';
import { TextField } from '../components/Inputs/TextField';
import { useKeyPressEnter } from '../hooks';
import yup from '../yup';
import DEFAULT_VALUES from './Pages/defaultValues';
import types from './Pages/types';
import VALIDATIONS from './Pages/validations';

const validationSchema = yup.object({
  packages: VALIDATIONS.packages.required(),
}).required();

const Packages = ({ serviceForm }) => {
  const submitRef = useKeyPressEnter();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    setValues, values, submitForm, errors, isValid, submitCount,
  } = useFormik({
    validationSchema,
    initialValues: {
      packages: serviceForm.values.packages,
    },
    onSubmit: (newValues) => {
      serviceForm.handlers.goToNextStep(newValues);
    },
  });

  const addNewPackage = () => setValues(
    over(
      lensProp('packages'),
      append(DEFAULT_VALUES.package),
    ),
  );

  const deletePackage = (idx) => setValues(
    over(
      lensProp('packages'),
      pipe(
        dissoc(idx),
        rValues,
      ),
    ),
  );

  const setPackageValue = (idx, name) => ({ target: { value } }) => {
    const isValidCharacter = /^[1-9]([0-9]{1,})?$/.test(String(value));
    if (value === '' || (value > 0 && isValidCharacter)) {
      setValues(
        assocPath(['packages', idx, name], value),
      );
    }
  };

  const getError = (idx, name) => (submitCount ? path(['packages', idx, name], errors) : undefined);

  return (
    <>
      <Typography variant="header-l" marginBottom={1}>
        {t('pages:packages.title')}
      </Typography>

      <Grid container spacing={3}>
        {
        values.packages.map((pkg, idx) => {
          const weightError = getError(idx, 'weight');
          const lengthError = getError(idx, 'length');
          const widthError = getError(idx, 'width');
          const heightError = getError(idx, 'height');
          return (
          // eslint-disable-next-line react/no-array-index-key
            <Grid item key={`${values.length}-${idx}`}>
              <Card
                title={values.packages.length > 1
                  ? (
                    <>
                      {t('pages:packages.package.title')}
                      <Typography color="neutral.60" variant="header-m" component="p">
                        {' '}
                        #
                        {idx + 1}
                      </Typography>
                    </>
                  )
                  : 'pages:packages.package.title'}
                {...(values.packages.length > 1 && {
                  button: (
                    <Button
                      sx={{
                        color: 'error.main',
                        padding: 0,
                        height: 'fit-content',
                        typography: {
                          fontSize: theme.typography['body-xs-bold'].fontSize,
                          fontWeight: theme.typography['body-xs-bold'].fontWeight,
                          lineHeight: theme.typography['body-xs-bold'].lineHeight,
                          letterSpacing: theme.typography['body-xs-bold'].letterSpacing,
                        },
                      }}
                      variant="text"
                      onClick={() => deletePackage(idx)}
                      startIcon={<DeleteIcon />}
                      data-test-selector="delete-package-button"
                    >
                      {t('pages:packages.package.deleteButton')}
                    </Button>),
                })}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      label="pages:packages.package.weight.label"
                      placeholder="pages:packages.package.weight.placeholder"
                      endAdornment="KG"
                      error={Boolean(weightError)}
                      helperText={weightError}
                      onChange={setPackageValue(idx, 'weight')}
                      value={pkg.weight}
                      data-test-selector="weight"
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    xs={12}
                  >
                    <TextField
                      type="number"
                      label="pages:packages.package.length.label"
                      placeholder="pages:packages.package.length.placeholder"
                      endAdornment="CM"
                      error={Boolean(lengthError)}
                      helperText={lengthError}
                      onChange={setPackageValue(idx, 'length')}
                      value={pkg.length}
                      data-test-selector="length"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      type="number"
                      label="pages:packages.package.width.label"
                      placeholder="pages:packages.package.width.placeholder"
                      endAdornment="CM"
                      onChange={setPackageValue(idx, 'width')}
                      error={Boolean(widthError)}
                      helperText={widthError}
                      value={pkg.width}
                      data-test-selector="width"
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      type="number"
                      label="pages:packages.package.height.label"
                      placeholder="pages:packages.package.height.placeholder"
                      endAdornment="CM"
                      error={Boolean(heightError)}
                      helperText={heightError}
                      onChange={setPackageValue(idx, 'height')}
                      value={pkg.height}
                      data-test-selector="height"
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          );
        })
      }
      </Grid>
      <Button
        variant="text"
        sx={{
          mt: 3,
          mb: 4,
        }}
        onClick={addNewPackage}
        startIcon={<AddCircleOutlineIcon />}
        data-test-selector="add-package-button"
      >
        {t('pages:packages.addPackage')}
      </Button>
      <FullButton
        _ref={submitRef}
        disabled={Boolean(submitCount && !isValid)}
        onClick={submitForm}
        data-test-selector="continue-button"
      >
        {t('pages:packages.nextStepButton')}
      </FullButton>
    </>
  );
};

Packages.propTypes = {
  ...types.serviceForm,
};

export default Packages;
