import { LOCALES } from '@bagonboard/wiseman';
import { useLocation } from 'react-router-dom';
import yup from '../yup';

const parametersValidation = yup.object({
  lang: yup.string().oneOf(Object.values(LOCALES)),
}).required();

export const useCleanParameters = () => {
  const location = useLocation();
  const params = Object.fromEntries(
    new URLSearchParams(location.search),
  );
  return parametersValidation.validateStripInvalid(params);
};
