/* Updated at Tue, 14 Dec 2021 13:41:54 GMT */

export default {
  borders: { main: '1px solid' },
  breakpoints: {
    __: '0px', medium: '600px', large: '900px', extralarge: '1200px',
  },
  colors: {
    'secondary-ultra-light': '#f3f5fe', 'secondary-main': '#0a48ec', 'secondary-dark': '#052476', 'secondary-light': '#cedafb', 'primary-main': '#cc0034', 'primary-light': '#ff99b3', 'primary-dark': '#990027', 'primary-dark30': '#6b001b', 'neutral-100': '#070e2c', 'neutral-90': '#202641', 'neutral-80': '#393e56', 'neutral-70': '#51566b', 'neutral-60': '#6a6e80', 'neutral-50': '#838796', 'neutral-40': '#9c9fab', 'neutral-30': '#cdcfd5', 'neutral-20': '#e6e7ea', 'neutral-10': '#f4f4f6', 'neutral-05': '#fcfcfc', 'neutral-00': '#ffffff', 'brand-main': '#f3a72e', 'success-main': '#229b3a', 'success-light': '#aff5a8', 'success-dark': '#0a5931', 'warning-main': '#fcb400', 'warning-light': '#feea98', 'warning-dark': '#925b00', 'error-main': '#e01d1d', 'error-light': '#f7c3c3', 'error-dark': '#8d0919', 'info-main': '#0d73e0', 'info-light': '#9cd8fb', 'info-dark': '#042e81',
  },
  radii: { m: '4px' },
  shadows: { sm: '0px 5px 15px rgba(0, 0, 0, 0.15)' },
  space: {
    xxs: '4px', xs: '8px', s: '16px', m: '24px', l: '32px', xl: '40px', xxl: '48px', xxxl: '80px',
  },
  fonts: {
    'elements-link': "'Montserrat', sans-serif", 'elements-overline': "'Montserrat', sans-serif", 'elements-caption': "'Montserrat', sans-serif", 'elements-button': "'Montserrat', sans-serif", 'marketing-l': "'Montserrat', sans-serif", 'marketing-xl': "'Montserrat', sans-serif", 'marketing-xxl': "'Montserrat', sans-serif", 'body-xxs-bold': "'Montserrat', sans-serif", 'body-xxs-medium': "'Montserrat', sans-serif", 'body-xxs-regular': "'Montserrat', sans-serif", 'body-xs-bold': "'Montserrat', sans-serif", 'body-xs-regular': "'Montserrat', sans-serif", 'body-s-bold': "'Montserrat', sans-serif", 'body-s-regular': "'Montserrat', sans-serif", 'header-m': "'Montserrat', sans-serif", 'header-l': "'Montserrat', sans-serif", 'header-xl': "'Montserrat', sans-serif", 'header-xxl': "'Montserrat', sans-serif",
  },
  fontSizes: {
    'elements-link': '12px', 'elements-overline': '12px', 'elements-caption': '12px', 'elements-button': '14px', 'marketing-l': '48px', 'marketing-xl': '61px', 'marketing-xxl': '97px', 'body-xxs-bold': '12px', 'body-xxs-medium': '12px', 'body-xxs-regular': '12px', 'body-xs-bold': '14px', 'body-xs-regular': '14px', 'body-s-bold': '16px', 'body-s-regular': '16px', 'header-m': '16px', 'header-l': '20px', 'header-xl': '24px', 'header-xxl': '32px',
  },
  fontWeights: {
    'elements-link': 600, 'elements-overline': 400, 'elements-caption': 500, 'elements-button': 600, 'marketing-l': 600, 'marketing-xl': 500, 'marketing-xxl': 500, 'body-xxs-bold': 600, 'body-xxs-medium': 500, 'body-xxs-regular': 400, 'body-xs-bold': 600, 'body-xs-regular': 400, 'body-s-bold': 600, 'body-s-regular': 400, 'header-m': 700, 'header-l': 700, 'header-xl': 700, 'header-xxl': 700,
  },
  letterSpacings: {
    'elements-link': '0.6000000000000001px', 'elements-overline': '0.18px', 'elements-caption': '0.1559999942779541px', 'elements-button': '0.7000000000000001px', 'marketing-l': '0px', 'marketing-xl': '0.305px', 'marketing-xxl': '-1.94px', 'body-xxs-bold': '0.06px', 'body-xxs-medium': '0.13200000286102295px', 'body-xxs-regular': '0.13200000286102295px', 'body-xs-bold': '0.07px', 'body-xs-regular': '0.07px', 'body-s-bold': '0.17600000381469727px', 'body-s-regular': '0.17600000381469727px', 'header-m': '0.24px', 'header-l': '0.06000000238418579px', 'header-xl': '0px', 'header-xxl': '0px',
  },
  lineHeights: {
    'elements-link': '16px', 'elements-overline': '12px', 'elements-caption': '16px', 'elements-button': '24px', 'marketing-l': '56px', 'marketing-xl': '72px', 'marketing-xxl': '108px', 'body-xxs-bold': '16px', 'body-xxs-medium': '16px', 'body-xxs-regular': '16px', 'body-xs-bold': '24px', 'body-xs-regular': '24px', 'body-s-bold': '24px', 'body-s-regular': '24px', 'header-m': '24px', 'header-l': '32px', 'header-xl': '32px', 'header-xxl': '48px',
  },
};
