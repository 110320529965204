export const ROUTE_KEY = {
  '/': '/',
  '/addresses': '/addresses',
  '/contact': '/contact',
  '/service-summary': '/service-summary',
};

export const ROUTES_KEYS = Object.keys(ROUTE_KEY);

export const FLOW_KEY = {
  main: 'main',
};

export const FLOWS_KEYS = Object.keys(FLOW_KEY);

export const localFormStorage = new Map();
