import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  equals,
  filter,
  length,
  not,
  pipe,
  prop,
  __,
} from 'ramda';

import ROUTES_OPTIONS from '../Pages/options';

const useStepServiceStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.neutral['70'],
  },
}));

const getProperStepsCount = pipe(
  filter(
    pipe(
      prop(__, ROUTES_OPTIONS),
      prop('stepCountDisabled'),
      not,
    ),
  ),
  length,
);

export const StepService = () => {
  const classes = useStepServiceStyles();
  const { status: { step, flow } } = useFormikContext();
  const { t } = useTranslation();

  const idx = flow.findIndex(equals(step));
  const stepsCount = getProperStepsCount(flow);

  return (
    <Typography variant="body-xxs-regular" className={classes.root}>
      {t('steps', { current: idx + 1, stepsCount })}
    </Typography>

  );
};
