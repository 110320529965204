import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export function FormInput({
  label, children,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant="body-xxs-medium" color="neutral.80">{t(label)}</Typography>
      {children}
    </div>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
