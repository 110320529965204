import { LOCALES } from '@bagonboard/wiseman';
import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import PropTypes from 'prop-types';
import { assoc } from 'ramda';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { I18N } from '../constants';
import config from './config';
import state from './state';

const I18N_CONFIG = {
  fallbackLng: LOCALES.es,
  defaultNS: I18N.defaultNamespace,
  load: 'languageOnly',
  ns: I18N.namespaces,
  debug: config.REACT_APP_HOST_ENV === 'local' && !process.env.REACT_APP_CI,
  backend: {
    loadPath: 'locales/{{lng}}/{{ns}}.json',
    requestOptions: { cache: 'no-store' },
  },
  react: {
    useSuspense: true,
  },
  detection: {
    caches: ['sessionStorage'],
  },
};

const LOCALES_MAPPER = {
  'en-GB': LOCALES.en,
  'es-ES': LOCALES.es,
};

export const I18nProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [{ lang }, setParameters] = useRecoilState(state.parameters);
  useEffect(() => {
    const lng = lang || process.env.REACT_APP_FORCE_LANG;
    async function loadI18n() {
      await i18next
        .use(HttpBackend)
        .use(detector)
        .use(initReactI18next)
        .init(
          assoc('lng', LOCALES_MAPPER[lng] || lng || LOCALES.es, I18N_CONFIG),
        );

      if (!lang && i18next.language) {
        setParameters(
          assoc('lang', i18next.language),
        );
      }
      setLoaded(true);
    }

    if (!loaded) loadI18n();

    return () => {
      if (loaded) setLoaded(false);
    };
  }, [loaded]);

  if (!loaded) return null;

  return children;
};

I18nProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
