import React, { StrictMode } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';

import App, { config } from './app';

const rootElement = document.getElementById('root');
const mustHydrate = rootElement.hasChildNodes();

if (config.REACT_APP_HOST_ENV !== 'local' && !mustHydrate) {
  if (config.REACT_APP_SENTRY_URL) {
    Sentry.init({
      dsn: config.REACT_APP_SENTRY_URL,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  } else {
    // eslint-disable-next-line no-console
    console.warn('missing sentry url');
  }
}

if (process.env.REACT_APP_GTM_KEY && !mustHydrate) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_KEY,
  });
}

const action = mustHydrate ? hydrate : render;

action(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  rootElement,
);

if (mustHydrate) {
  // reportWebVitals(console.log);
}
