import { atom } from 'recoil';

export const DEFAULT_STATE_VALUES = {
  parameters: {},
  service: {
    requesting: false,
    success: false,
    error: null,
    data: null,
  },
};

const parameters = atom({
  key: 'parameters',
  default: DEFAULT_STATE_VALUES.parameters,
});

const service = atom({
  key: 'service',
  default: DEFAULT_STATE_VALUES.service,
});

const state = {
  parameters,
  service,
};

export const setInitialState = (initialState) => ({ set }) => {
  Object.keys(initialState).forEach((k) => {
    const value = initialState[k];
    const currAtom = state[k];
    set(currAtom, value);
  });
};

export default state;
