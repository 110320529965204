import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { RecoilRoot } from 'recoil';

import Pages from '../pages';
import theme from '../theme';
import { I18nProvider } from './i18n';
import { useCleanParameters } from './parameters';
import { setInitialState } from './state';

const App = () => {
  const parameters = useCleanParameters();
  return (
    <React.StrictMode>
      <RecoilRoot initializeState={setInitialState({ parameters })}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <I18nProvider>
            <SnackbarProvider
              maxSnack={5}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Pages />
            </SnackbarProvider>
          </I18nProvider>
        </ThemeProvider>
      </RecoilRoot>
    </React.StrictMode>
  );
};

export default App;
