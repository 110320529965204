import { useCallback, useEffect, useRef } from 'react';

/**
 * Hook to include submit on press enter key behavior in a custom form
 *
 * @returns Button Ref
 */
export const useKeyPressEnter = () => {
  const ref = useRef();

  const onClick = useCallback((e) => {
    if (e.code === 'Enter' && ref.current) ref.current.click();
  }, [ref.current]);

  useEffect(() => {
    document.addEventListener('keypress', onClick);

    return () => {
      document.removeEventListener('keypress', onClick);
    };
  }, [onClick]);

  return ref;
};
