import PropTypes from 'prop-types';

const serviceForm = {
  serviceForm: PropTypes.shape({
    values: PropTypes.objectOf(PropTypes.any).isRequired,
    handlers: PropTypes.shape({
      goToNextStep: PropTypes.func.isRequired,
      snapshotState: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};

const types = {
  serviceForm,
};

export default types;
