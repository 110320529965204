import { InputAdornment, TextField as MuiTextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormInput } from './FormInput';

export const TEXTFIELD_INPUT_STYLE = ({ palette, typography, spacing }, endAdornment) => ({
  '& .MuiOutlinedInput-root': {
    pr: endAdornment ? 1 : 0,
    height: spacing(5),
    '& .MuiOutlinedInput-input': {
      fontSize: typography['body-s-regular'].fontSize,
      fontWeight: typography['body-s-regular'].fontWeight,
      letterSpacing: typography['body-s-regular'].letterSpacing,
      p: 1,
      background: palette.neutral['10'],
      height: 'calc(38px - 16px)',
    },
    '& fieldset': {
      borderColor: palette.neutral['30'],
    },
    '& .MuiInputBase-inputAdornedEnd': {
      border: `1px solid ${palette.neutral['30']}`,
      borderRadius: endAdornment ? '4px 0 0 4px' : '4px 4px 4px 4px',
    },
    '& .MuiInputAdornment-root .MuiTypography-root': {
      color: palette.secondary.main,
      fontSize: typography['body-xs-bold'].fontSize,
      fontWeight: typography['body-xs-bold'].fontWeight,
      letterSpacing: typography['body-xs-bold'].letterSpacing,
      textTransform: 'uppercase',
    },
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    '& .MuiOutlinedInput-input': {
      background: 'white',
    },
    '& fieldset': {
      borderColor: palette.secondary.main,
    },
    '& .MuiInputBase-inputAdornedEnd': {
      borderRight: `2px solid ${palette.secondary.main}`,
      marginRight: '-1px',
    },
  },
});

export function TextField({
  variant, label, endAdornment, value, placeholder, helperText, ...props
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <FormInput label={label}>
      <MuiTextField
        value={value}
        variant={variant}
        helperText={helperText ? t(helperText) : undefined}
        placeholder={placeholder ? t(placeholder) : undefined}
        sx={TEXTFIELD_INPUT_STYLE(theme, endAdornment)}
        InputProps={endAdornment && {
          endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
        }}
        {...props}
      />
    </FormInput>
  );
}

TextField.propTypes = {
  value: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  endAdornment: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

TextField.defaultProps = {
  value: '',
  endAdornment: undefined,
  helperText: undefined,
  placeholder: undefined,
  variant: 'outlined',
  disabled: false,
};
