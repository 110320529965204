import { LOCALES } from '@bagonboard/wiseman';
import yup from '../../yup';
import { FLOW_KEY } from './constants';

const packageValidation = yup.object({
  weight: yup.number().required().min(1),
  length: yup.number().required().min(1),
  width: yup.number().required().min(1),
  height: yup.number().required().min(1),
});

const shippingDate = yup.date('common:yup.date.invalid').min(
  new Date(),
  'common:yup.date.today',
).test(
  'is-weekend',
  'common:yup.date.invalidServiceWeekend',
  (value) => ![0, 6].includes(value?.getDay()),
);

const address = yup.object({
  address: yup.string().required(),
  country: yup.string().required(),
  city: yup.string().required(),
  zip: yup.string().required(),
  details: yup.string(),
});

const contact = yup.object({
  name: yup.string().required(),
  surname: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
});

const language = yup.string().oneOf(Object.values(LOCALES)).required();

const packages = yup.array().of(packageValidation.required()).min(1).max(100);

const keepUpdates = yup.bool();
const agreeConditions = yup.bool().oneOf([true]);

export const FLOW_VALIDATIONS = {
  [FLOW_KEY.main]: yup.object({
    contact: contact.required(),
    destination: address.required(),
    keepUpdates,
    origin: address.required(),
    packages: packages.required(),
    shippingDate: shippingDate.required(),
    agreeConditions: agreeConditions.required(),
    language: language.required(),
  }).required(),
};

const VALIDATIONS = {
  address,
  contact,
  keepUpdates,
  package: packageValidation,
  packages,
  shippingDate,
  agreeConditions,
};

export default VALIDATIONS;
