import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@mui/styles';

const useFooterStyles = makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.light,
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 16px',
    gap: '24px',
    alignItems: 'center',
    textAlign: 'center',
  },
  linksContainer: {
    display: 'flex',
    gap: '80px',
    margin: '24px 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '24px',
    },
  },
  link: {
    textDecoration: 'none',
  },
  socialContainer: {
    display: 'flex',
    gap: '24px',
  },
  social: {
    color: theme.palette.secondary.light,
  },
  line: {
    height: '1px',
    backgroundColor: theme.palette.secondary.light,
    width: '100%',
    maxWidth: '1120px',
  },
}));

const Footer = () => {
  const classes = useFooterStyles();
  const { t } = useTranslation();
  return (
    <footer className={classes.root}>
      <img src="/img/logo-alt.svg" width={58} height={32} alt="logo" />
      <div className={classes.linksContainer}>
        {t('components.footer.links', { returnObjects: true }).map(({ text, href }) => (
          <Link
            key={`${href}-link`}
            variant="body-xxs-regular"
            className={classes.link}
            href={href}
            color="secondary.light"
            underline="none"
            target="_blank"
            rel="noopener"
          >
            {text}
          </Link>
        ))}
      </div>

      <div className={classes.socialContainer}>
        {t('components.footer.social', { returnObjects: true }).map(({ icon, href }, idx) => (
          <Link
            // eslint-disable-next-line react/no-array-index-key
            key={`${href}-${idx}`}
            variant="body-xxs-regular"
            className={classes.social}
            href={href}
            target="_blank"
            rel="noopener"
          >
            <img src={`/img/${icon}.svg`} alt={icon} />
          </Link>
        ))}
      </div>
      <div className={classes.line} />
      <Typography variant="body-xxs-regular">
        {t('components.footer.copyright', { year: new Date().getFullYear() })}
      </Typography>
    </footer>
  );
};

export default Footer;
