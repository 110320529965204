import yup from '../yup';

import { HOST_ENVS } from '../constants';

const configValidation = yup.object({
  REACT_APP_HOST_ENV: yup.string().oneOf(Object.values(HOST_ENVS)).required(),
  REACT_APP_BOB_API: yup.string().required(),
  // TODO: Check required
  REACT_APP_SENTRY_URL: yup.string(),
  REACT_APP_GTM_KEY: yup.string(),
}).required();

export default configValidation.validateSync(process.env);
