import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    borderColor: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderWidth: '1px',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.drawer,
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(7),
    width: '100%',
    maxWidth: theme.spacing(120),
    margin: '0 auto',
  },
  backLink: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.neutral['100'],
    textDecoration: 'none',
    lineHeight: '24px',
    fontSize: '14px',

    [theme.breakpoints.up('sm')]: {
      left: theme.spacing(3),
    },

    [theme.breakpoints.up('md')]: {
      left: theme.spacing(5),
    },

    '& svg': {
      height: '14px',
    },
  },
  logo: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Header = ({
  backRoute, logoRoute, logoCallback, backCallback,
}) => {
  const classes = useHeaderStyles();
  const { t } = useTranslation();
  return (
    <header className={classes.root}>
      <div className={classes.container}>
        {backRoute && (
        <Link
          onClick={backCallback}
          to={backRoute}
          className={classes.backLink}
        >
          <ArrowBackIosIcon />
          <Typography variant="body-xs-regular">
            {t('common:back')}
          </Typography>
        </Link>
        )}
        <Link
          to={logoRoute}
          onClick={logoCallback}
          className={classes.logo}
        >
          <img src="/img/logo.svg" alt="BoB logo" height="32" width="58" />
        </Link>
      </div>
    </header>
  );
};

Header.propTypes = {
  backRoute: PropTypes.string,
  backCallback: PropTypes.func,
  logoRoute: PropTypes.string,
  logoCallback: PropTypes.func,
};

Header.defaultProps = {
  backRoute: undefined,
  backCallback: undefined,
  logoRoute: '/',
  logoCallback: undefined,
};
