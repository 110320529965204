/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'common:yup.mixed.required',
  },
  string: {
    email: 'common:yup.string.email',
  },
  number: {
    min: 'common:yup.number.min',
  },
});

const findFieldInInnerErrors = (inner, key) => !inner.find(
  ({ path, params: { unknown } }) => path?.split('[').shift() === key || unknown === key,
);

const removeFieldsWithError = (error) => {
  const { value, inner = [] } = error;
  return Object.entries(value).reduce(
    (all, [key, innerValue]) => ({
      ...all,
      ...(findFieldInInnerErrors(inner, key) && {
        [key]: innerValue,
      }),
    }),
    {},
  );
};

export function validateStripInvalid(values) {
  try {
    const args = [
      values,
      {
        abortEarly: false,
        recursive: true,
        stripUnknown: true,
      },
    ];
    this.noUnknown().strict().validateSync(...args);
    return this.noUnknown().cast(...args);
  } catch (error) {
    return removeFieldsWithError(error);
  }
}

yup.addMethod(yup.mixed, 'validateStripInvalid', validateStripInvalid);

export default yup;
